<template>
    <a-modal v-model="visible" title="设备二维码" :destroyOnClose="true"
             okText="完成">

        <a-alert message="请在微信端扫描二维码绑定设备" type="info" />

        <div class="qrcode" ref="qrCodeUrl"></div>


    </a-modal>
</template>

<script>

    import QRCode from 'qrcodejs2'

    export default {
        props:["content"],
        data(){
            return {
                visible:false
            }
        },
        mounted() {
            // this.renderQrCode()
        },
        methods:{
            renderQrCode(){
                new QRCode(this.$refs.qrCodeUrl, {
                    text: this.content, // 需要转换为二维码的内容
                    width: 200,
                    height: 200,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            },
            show(){
                this.visible=true
                setTimeout(()=>{
                    this.renderQrCode()
                },300)

            }
        }
    }
</script>

<style lang="less" scoped>
    .qrcode-container{
        background: #999;

    }

    .qrcode{
        width: 200px;
        height: 200px;
        margin: 20px auto;
    }

</style>
